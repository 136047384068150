import {Auth} from '../../modules/auth.js';

/**
 * Implements behavior for the custom element <rt-editor>
 */
class RichTextEditor extends HTMLElement {
    static formAssociated = true;

    /** Called each time the element is added to the document. */
    connectedCallback() {
        // add listeners early to make sure they will be processed first
        this.addEventListener('rte:toggle', this._toggleEditor.bind(this));
        this.addEventListener('rte:change', this._syncTextarea.bind(this));

        if (!this._rteApi) {
            this.setAttribute('aria-busy', 'true');
            // load RTE implementation script files
            import('../../components/richTextEditor/prosemirror/editor.js').then(({ProseMirrorEditor}) => {
                this._initEditor(ProseMirrorEditor);
                this.setAttribute('aria-busy', 'false');
            });
        }
    }

    /**
     * Creates a new instance of RTE
     * @param {Function} ProseMirrorEditor RTE implementation class
     */
    _initEditor(ProseMirrorEditor) {
        this._editor = document.createElement('rt-content');
        // mark editor root element with custom class name
        this._editor.className = 'ProseMirror-wrapper';

        this._textarea = this.querySelector('textarea');
        if (!this._textarea) {
            this._textarea = document.createElement('textarea');
            this._textarea.placeholder = this.getAttribute('placeholder') || '';
            this._textarea.className = 'rt-editor__textarea';
            this.append(this._textarea);
        }
        this.insertBefore(this._editor, this._textarea);

        const widgetSrcset = this.getAttribute('widget-srcset') || '';

        this._rteApi = new ProseMirrorEditor(this, this._editor, {
            // source HTML menu is hidden by default
            sourceEnabled: this.getAttribute('source') === 'on',
            // headings menu is hidden by default
            headingEnabled: this.getAttribute('heading') === 'on',
            // show insert menu only for authorized members
            insertEnabled: !Auth.guest() && this.getAttribute('insert') !== 'off',
            // table menu is hidden by default
            tableEnabled: this.getAttribute('table') === 'on',
            // table of contents is hidden by default
            tocEnabled: this.getAttribute('toc') === 'on',
            // block menu is visible by default
            blockEnabled: this.getAttribute('block') !== 'off',
            // ad placements are hidden by default
            adsEnabled: this.getAttribute('ads') === 'on',
            widgetSources: widgetSrcset.split(','),
            placeholder: this._textarea.placeholder,
            compact: this.hasAttribute('compact'),
            uploadDestination: this.getAttribute('upload-destination'),
        });

        this._textarea.setAttribute('aria-hidden', 'true');
        this._textarea.setAttribute('spellcheck', 'false');
        // <textarea> is used only to store RTE output
        this._textarea.removeAttribute('placeholder');

        this._toggleEditor();
        // notify listeners that RTE is ready for use
        this.dispatchEvent(new CustomEvent('rte:ready', {bubbles: true}));
    }

    /**
     * Handles RTE toggle event to update <textarea>
     */
    _toggleEditor() {
        const editable = this._rteApi.editable;
        this._textarea.hidden = editable;
        // sync RTE with latest textarea value
        if (editable) {
            this._rteApi.setContent(this._textarea.value);
        } else {
            // set textarea value to pretty formatted RTE content
            this._textarea.value = this._rteApi.getContent(true);
        }
    }

    /**
     * Synchronizes <textarea> value with RTE content
     */
    _syncTextarea() {
        this._textarea.value = this._rteApi.getContent();
    }

    /**
     * Gets RTE content as HTML string
     * @returns {string} current content of RTE
     */
    get editorHTML() {
        return this._textarea.value;
    }

    /**
     * Sets RTE content
     * @param {string} htmlString new content
     */
    set editorHTML(htmlString) {
        this._rteApi.setContent(htmlString);
        this._syncTextarea();
    }

    /**
     * Moves caret to RTE area
     */
    focus() {
        this._rteApi.focus();
    }
}

customElements.define('rt-editor', RichTextEditor);
